import { useState, useEffect } from 'react';

const useWindowDimensions = (): { width: number; height: number } => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;

    return { width, height };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    const onResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
